<template>
  <div class="s-form-input">
    <Field :name="name" :label="label" :error="error" :help="help">
      <div class="s-relative">
        <input
          class="s-form-control"
          :id="name"
          :name="name"
          :type="showPassword ? 'text' : type"
          :value="value"
          :pattern="pattern"
          :maxlength="maxlength"
          :inputmode="inputmode"
          :placeholder="placeholder"
          :disabled="disabled"
          :required="required"
          :readonly="readonly"
          @input="$emit('input', $event.target.value)"
        />
        <div class="s-eye-icon" @click="showPassword = !showPassword" v-if="type === 'password'">
          <img src="@/assets/images/liff/eye.png" v-if="showPassword">
          <img src="@/assets/images/liff/eye-slash.png" v-else>
        </div>
      </div>
    </Field>
  </div>
</template>

<script>
  import mixin from '@/mixins/liff/groupBuying/form'

  export default {
    mixins: [mixin],
    props: {
      type: {
        type: String,
        default: 'text',
      },
      inputmode: {
        type: String,
        default: 'text',
      },
      pattern: {
        type: String,
        default: null,
      },
      maxlength: {
        type: [String, Number],
        default: '',
      },
    },
    data: () => ({
      showPassword: false,
    }),
  }
</script>

<style scoped>
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .s-eye-icon {
    position: absolute;
    right: 0.5rem;
    bottom: 50%;
    transform: translateY(50%);
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .s-eye-icon img {
    width: 100%;
  }
</style>
