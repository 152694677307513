<template>
  <label class="s-form-checkbox" :for="name">
    <input
      type="checkbox"
      :id="name"
      :name="name"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <slot />
  </label>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      checked: {
        type: Boolean,
      },
    },
    model: {
      event: 'change',
      prop: 'checked',
    },
  }
</script>

<style scoped>
  .s-form-checkbox {
    display: inline-flex;
    align-items: center;
  }

  .s-form-checkbox input {
    margin-right: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: .25rem;
    border: solid 1px var(--s-secondary);
  }
</style>
