<template>
  <div>
    <PageTitle>請輸入會員密碼</PageTitle>
    <Form :action="submit" :after-action="afterSubmit" v-slot="{ busy }">
      <div class="s-space-y-3 s-mb-2">
        <Input
          label="手機號碼"
          type="text"
          inputmode="tel"
          name="mobileNumber"
          placeholder="0987654321"
          pattern="\d*"
          maxlength="10"
          v-model="mobileNumber"
          :readonly="mobileNumberIsReadOnly"
          required
        />
        <Input label="密碼" type="password" name="password" v-model="form.password" required />
      </div>
      <div class="s-mb-10 s-flex s-justify-between">
        <Checkbox name="agreement" v-model="agreement">
          <span class="s-mr-1">我同意</span>
          <a class="s-link s-link-secondary" href="#" @click.prevent="$refs.rules.open()">會員權益聲明</a>
        </Checkbox>
        <router-link
          class="s-link s-link-secondary"
          :to="{ name: 'LiffGroupBuyingAuthPassword', query: $route.query }"
        >
          忘記密碼
        </router-link>
      </div>
      <button class="s-btn s-btn-primary" type="submit" :disabled="!formIsValid || busy">
        確定
      </button>
    </Form>
    <Modal ref="rules" title="會員權益聲明">
      <p>歡迎您成為寶雅國際股份有限公司(含寶家，下稱本公司)會員，您即刻可享有本公司提供之各項服務。以下是您使用本公司提供之各項服務時，需配合注意之事項及相關約定，並於您使用本公司提供之服務時，即表示您同意接受並遵守本聲明事項約定。</p>

      <p class="s-mb-4"></p>
      <p class="s-text-secondary">會員註冊流程</p>
      <p>一、成為會員：填妥會員申請資料並完成申請流程，可免費申辦本公司APP行動會員卡。</p>
      <p>二、單一手機號碼申請一組行動會員卡，為確保會員權益，限本人親自申請，一人限辦一次會員資格，會員資格不得轉讓、代辦或重複申辦，恕不接受退卡與退費。</p>
      <p>三、已申請APP行動會員卡資格，將不會再發放實體會員卡片。</p>

      <p class="s-mb-4"></p>
      <p class="s-text-secondary">會員義務與責任</p>
      <p>一、當您同意成為本公司會員並申辦會員申請書時，即表示您已閱讀、瞭解並同意接受本聲明事項約定之所有內容。</p>
      <p>二、您應於使用本公司會員服務時，提供您本人正確、最新的資料，且無冒用、偽造或變造之不實資料欺偽註冊等情事，並請定期維護及即時更新您個人資料之正確性，以獲取最佳之服務。若您提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料時，本公司有權不經事先通知，逕行暫停或終止您的帳號，並拒絕您使用本服務之全部或一部份。</p>
      <p>三、為提供您最完善的服務並保護您的個人資料，本公司謹此依個人資料保護法（下稱「個資法」）規定，告知您如下事項：</p>
      <p>3.1 蒐集目的、個人資料類別、利用期間、地區、對象及方式：</p>
      <p>為提供您有關本公司各項消費優惠、商品、服務或活動及其最新資訊，並有效管理會員資料或進行滿意度及消費統計分析調查（下稱「蒐集目的」），本公司將於上開蒐集目的消失前，在臺灣地區或完成上開蒐集目的之必要地區內，蒐集、處理、利用您填載於會員申請書之個人資料(包括但不限於姓名、電話、地址、性別、密碼、生日等)，或日後經您同意而提供之其他個人資料。您同意本公司及本公司相關業務合作夥伴(含相關履行輔助人及代理人)，在為提供本服務行銷、市場分析、統計或研究、或為提供會員個人化服務或加值服務之目的範圍內，得記錄、保存及利用您在本服務所留存或產生的資料及記錄，或提供您所需的行銷活動及相關優惠訊息給您。前述活動及服務訊息發送方式包括但不限於簡訊、E-mail、EDM、電話聯絡、郵寄、APP、LINE訊息。</p>
      <p>3.2 權利行使事項：</p>
      <p>您可透過本公司客服專線(寶雅0800-033-168、寶家0800-033-158)行使下列權利，除個資法另有規定外，本公司不會拒絕，並得酌收必要成本費用：</p>
      <ul class="s-list-disc s-list-inside">
        <li>查詢或請求閱覽您的個人資料。</li>
        <li>製作給您個人資料複本。</li>
        <li>補充或更正您的個人資料。</li>
        <li>停止蒐集、處理、利用您的個人資料。</li>
        <li>刪除您的個人資料。</li>
        <li>您的拒絕接受行銷。</li>
      </ul>
      <p>3.3 注意事項：</p>
      <p>如您不同意提供個人資料或要求刪除或停止蒐集、處理或利用您的個人資料，您瞭解本公司可能因此無法進行核卡資格審核及相關處理作業、或提供您完善的服務、或必需採取停卡、終止會員措施，尚請見諒。</p>
      <p>3.4 在下列的情況下，本公司可能會查看或提供會員的個人資料或相關電信資料給相關政府機關、或主張其權利受侵害並提出適當證明之第三人：</p>
      <ul class="s-list-disc s-list-inside">
        <li>依法令規定、或依司法機關或其他相關政府機關的命令。</li>
        <li>會員涉及違反法令、侵害第三人權益、或違反本使用條款或各該使用規範或約定。</li>
        <li>為保護會員服務系統之安全或經營者之合法權益。</li>
        <li>為保護其他使用者或其他第三人的合法權益。</li>
      </ul>
      <p>3.5 會員資料變更修改：</p>
      <p>您可於本公司門市、本公司APP會員專區登入您的會員卡號、密碼及驗證碼，線上即時查閱或更新您的個人資料。請務必妥善保管帳號、密碼資料，若您不慎外洩或委託他人代為登入者，您將自負完全的責任。</p>

      <p class="s-mb-4"></p>
      <p class="s-text-secondary">會員紅利點數</p>
      <p>一、點數累積：於POYA Buy寶雅線上買、POYA Buy寶家線上買、及本公司所有相關線上購物平台(下稱本網站)消費結帳前，應先登入會員。</p>
      <p>二、集點說明：於本網站訂單完成取貨或送達後，隔天會入點數。(如2021/6/23下單完成後，於2021/06/25收到包裹並完成訂單，點數將於2021/06/26入點) 運費、代收手續費、退貨商品金額不列入點數計算。</p>
      <p>三、折抵說明：於本網路消費結帳前登入會員，結帳時系統自動計算訂單最高可折抵點數(不 可自訂折抵點數/金額)，最高可折抵單筆結帳金額30%，活動、折價券、點數可同時使用，運費不可用點數折抵(折抵點數後未達免運門檻需付運費)、滿額贈禮活動（如折抵後未達贈禮門檻金額，將無法享有贈品）。</p>
      <p>四、正當使用：點數限本人使用，為維護您的權益，請勿將您的會員卡號或手機號碼、住家電話等個人資料透過電子郵件、網路公告等公開傳播方式或其他不當之方法來累積點數，如有發現上述行為，本公司保留將不當紅利點數積點扣除及終止會員卡權益之權利。</p>
      <p>五、點數效期：當年度所發出之點數至隔年12月31日失效(例如:2021/01/01-12/31所發出之點數至2022/12/31失效並於2023/01/01清除點數，依此類推)。</p>
      <p>七、贈點除外：使用點數所兌換的商品或折抵之消費金額，將不再另行給予點數、贈品。</p>
      <p>八、點數扣除：商品符合退換貨服務並進行退換貨時，會自會員帳戶中扣除原購物發票上所給予之紅利點數總額。如點數不足時，會員帳戶中紅利點數將紀錄為負值，於再次消費時將自動先行扣抵該筆交易所生之紅利點數，至消費所獲得的點數可完全抵扣全部負值為止。</p>
      <p>九、查詢點數：可利用本公司官網「會員專區」、或POYAPAY APP「會員專區」消費紀錄查詢。</p>

      <p class="s-mb-4"></p>
      <p class="s-text-secondary">暫停服務</p>
      <p>於發生下列情形之一時，本公司有權可以停止、中斷提供各項服務：</p>
      <ol class="s-list-decimal s-list-inside">
        <li>本網站相關軟硬體設備進行搬遷、更換、升級、保養或維修時。</li>
        <li>發生突發性之電子通信設備故障、或登入之電子通信服務被停止，無法提供服務時。</li>
        <li>天災等不可抗力之因素或其他不可歸責於本公司致使無法提供服務時。</li>
        <li>您有任何違反法令或本聲明情形時。</li>
      </ol>

      <p class="s-mb-4"></p>
      <p class="s-text-secondary">其他約定事項</p>
      <p>一、本網站所提供之任何資訊，包括但不限於文字、軟體、音樂、聲音、影像、圖片、動態影像或其他相關資料、素材等，均受本公司及合作店家之著作權、商標權、專利權等智慧財產權及相關法律保護，未經本公司同意或授權，您不得擅自為重製、改作、公開傳輸、散佈等利用行為，如您因違反本條規定而發生侵害權利之情事時，您應自行負擔完全法律責任，本公司並保留一切法律追訴權。</p>
      <p>二、就您的行為是否符合會員規範(包含但不限本聲明事項)，本公司有最終決定之權利。若確定您的行為違反會員規範或法令規定，本公司得隨時停止、終止您使用行動會員卡與點數權利。而您若有違反相關法令規定或違背會員規範(包含但不限本聲明事項)條款情事，致本公司、關係企業、合作廠商、受僱人、受託人、代理人及其他相關履行輔助人因此而受有損害或支出費用(包括且不限於因進行民事、刑事及行政程序所支出之律師費用)時，您除自負法律責任外亦應負擔損害賠償責任或填補費用。</p>
      <p>三、本公司保有行動會員卡及其會員專屬優惠、福利、相關活動之解釋、修改、調整、終止等相關權利。相關辦法及未盡事宜以本公司官網、本公司APP公告為主，並於公告後生效，不再另行個別通知。</p>
      <p>四、本聲明之解釋與適用，及與本聲明有關爭議或使用本網站所生爭議，均以中華民國法律為準據法，並以臺灣臺南地方法院為第一審管轄法院。</p>
      <p>五、以上聲明效力持續有效。</p>
    </Modal>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'
  import Input from '@/components/Page/Liff/Form/Input'
  import Checkbox from '@/components/Page/Liff/Form/Checkbox'
  import Modal from '@/components/Page/Liff/Shared/Modal'
  import Form from '@/components/Page/Liff/Form/Form'

  export default {
    components: { PageTitle, Input, Checkbox, Modal, Form },
    data: () => ({
      form: {
        mobileNumber: '',
        password: '',
      },
      agreement: false,
    }),
    computed: {
      mobileNumber: {
        get () {
          return this.$route.query.mobile || this.form.mobileNumber
        },
        set (value) {
          this.form.mobileNumber = value
        },
      },
      mobileNumberIsReadOnly () {
        return !!this.$route.query.mobile
      },
      mobildNumberIsValid () {
        return /\d{10}/.test(this.mobileNumber)
      },
      passwordIsValid () {
        return !!this.form.password
      },
      formIsValid () {
        return this.mobildNumberIsValid && this.passwordIsValid && this.agreement
      },
    },
    methods: {
      ...mapActions({
        login: 'liffGroupBuying/login',
      }),
      async submit() {
        await this.login({
          mobileNumber: this.mobileNumber,
          password: this.form.password,
        })
      },
      afterSubmit () {
        this.$router.push({ name: 'LiffGroupBuyingAuthBound' })
      },
    },
  }
</script>
